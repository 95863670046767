<template>
  <b-container class="amain">
    <h1>Admin Stran</h1>
    <b-row>
      <b-col cols="6"><b-button @click="$router.replace('/admin/Images')" variant="primary">Slike</b-button></b-col>
      <b-col cols="6"><b-button @click="logout" variant="primary">Logout</b-button></b-col>
    </b-row>
  </b-container>
</template>

<script>
import { auth } from '../../plugins/firebase';

export default {
  name: 'Admin',
  methods: {
      async logout () {
        await auth.signOut()
        this.$router.replace('/');
      }
  }
}
</script>

<style scoped>
.amain {
  margin-top: 8%;
}
</style>